
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager.ts";
  import Shift from "@/typesold/shift";
  import shiftbundle_manager from "@/vuex/shift/shiftbundle_manager";
  import realtime from "@/vuex/shift/realtime";
  import UtilPopup from "@/components/util/Popup.vue";
  import ShiftDialogShiftbundleMonthchanger from "@/components/shift/dialog/shiftbundle/Monthchanger.vue";
  import ShiftDialogShiftbundleControlbutton from "@/components/shift/dialog/shiftbundle/Controlbutton.vue";
  import ShiftDialogShiftbundleShiftbundlecontrol from "@/components/shift/dialog/shiftbundle/Shiftbundlecontrol.vue";
  import ShiftDialogShiftbundleApplybutton from "@/components/shift/dialog/shiftbundle/Applybutton.vue";
  import ShiftDialogShiftbundleShiftbundletable from "@/components/shift/dialog/shiftbundle/Shiftbundletable.vue";

  @Component({
    components: {
      UtilPopup,
      ShiftDialogShiftbundleMonthchanger,
      ShiftDialogShiftbundleControlbutton,
      ShiftDialogShiftbundleShiftbundlecontrol,
      ShiftDialogShiftbundleApplybutton,
      ShiftDialogShiftbundleShiftbundletable,
    },
  })
  export default class Main extends Mixins(mixinShift) {
    get is_display() {
      if (this.m.is_open_shiftbundle_dialog) {
        shiftbundle_manager.getShifts({
          yearmonth: this.shiftbundle_date,
          branch_id: this.viewing_branch,
        });
      }
      return this.m.is_open_shiftbundle_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenShiftbundleDialog(val);
    }

    get yearmonthstr() {
      return util.formatDate(this.shiftbundledateobj, "yyyy年mm月");
    }

    create_shiftbundle() {
      shiftbundle_manager.createShiftbundle({
        yearmonth: this.shiftbundle_date,
        branch_id: this.viewing_branch,
      });
    }
  }
