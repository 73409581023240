
  import { Component, Watch, Mixins } from "vue-property-decorator";
  import dragdrop from "dragdrop";
  import { Tooltip } from "ant-design-vue";
  import mixinShift from "@/mixins/mixinShift";
  import * as util from "@/util";
  import CommonButton from "@/components/common/Button.vue";
  import ShiftDialogShiftDialog from "@/components/shift/dialog/shift/Dialog.vue";

  @Component({
    components: {
      CommonButton,
      Tooltip,
      ShiftDialogShiftDialog,
    },
  })
  export default class Main extends Mixins(mixinShift) {
    //employees = {employee_id: employee{id, name, code}}
    //lists = {employee_id : {date : {shift: object | null, shift_pattern_id: number | null, able: object | null}}}
    //shift_patterns = {shift_pattern_id: shift_pattern{name, color}}
    //date_array = ['yyyy-mm-dd']

    // employees = {1: {id: 1, name: '千速 一真', code: '0001'}};
    // lists = {1: {'2021-09-01' : {shift: {}, shift_pattern_id: 1, able: null}}};
    // shift_patterns = {1: {name: 'テストパターン', color: 'FF0000'}};

    get row_length() {
      const row = this.lists.lists.length + 1;
      return Math.min(row, 100);
    }

    rightClick(data) {
      this.m.setListRightClick(data);
      this.m.setIsOpenListRightClickDialog(true);
    }

    created() {
      this.setDrag();
    }

    @Watch("editable_shift")
    @Watch("lists")
    @Watch("yearmonth")
    @Watch("viewing_branch")
    setDrag() {
      const _this = this;
      const shift_autofill = {
        startDrag(dataset) {
          if (_this.editable_shift) {
            $(`.autofill-${dataset.row}-${dataset.col}`).addClass("hover_source");
          }
        },
        async endDrag(origin_dataset, dragging_dataset, dest_dataset) {
          if (origin_dataset.row != dest_dataset.row || origin_dataset.col != dest_dataset.col) {
            console.log("autofill", { row: origin_dataset.row, col: origin_dataset.col }, "to", {
              row: dest_dataset.row,
              col: dest_dataset.col,
            });
            await _this.m.autofillShift({
              origin_row: origin_dataset.row,
              origin_col: origin_dataset.col,
              dest_row: dest_dataset.row,
              dest_col: dest_dataset.col,
              employee_ids: _this.lists.employee_ids,
              yearmonth: _this.yearmonth,
              branch_id: _this.viewing_branch,
            });
          }
          $(".shift_block, .no_shift_block").removeClass("hover");
          $(".shift_block, .no_shift_block").removeClass("hover_source");
        },
        cancelDrag() {
          $(".shift_block, .no_shift_block").removeClass("hover");
          $(".shift_block, .no_shift_block").removeClass("hover_source");
        },
        mouseenterDrag(origin_dataset, dragging_dataset, hover_dataset) {
          let selector = "";

          const startrow = Math.min(origin_dataset.row, hover_dataset.row);
          const startcol = Math.min(origin_dataset.col, hover_dataset.col);
          const endrow = Math.max(origin_dataset.row, hover_dataset.row);
          const endcol = Math.max(origin_dataset.col, hover_dataset.col);

          for (let nowrow = startrow; nowrow <= endrow; nowrow++) {
            for (let nowcol = startcol; nowcol <= endcol; nowcol++) {
              if (selector == "") {
                selector += `.autofill-${nowrow}-${nowcol}`;
              } else {
                selector += `,.autofill-${nowrow}-${nowcol}`;
              }
            }
          }

          $(".shift_block, .no_shift_block").removeClass("hover");
          $(selector).addClass("hover");
        },
        mouseleaveDrag(origin_dataset, dragging_dataset, hover_dataset) {},
      };

      const shift_manipulate = {
        startDrag(dataset) {
          if (_this.editable_shift) {
            $(`.autofill-${dataset.row}-${dataset.col}`).addClass("hover_source");
          }
        },
        async endDrag(origin_dataset, dragging_dataset, dest_dataset) {
          // console.log('manipulate', {row: origin_dataset.row, col: origin_dataset.col}, 'to', {row: dest_dataset.row, col: dest_dataset.col});
          // _this.m.setManipulate({origin_row: origin_dataset.row, origin_col: origin_dataset.col, dest_row: dest_dataset.row, dest_col: dest_dataset.col, employee_ids: _this.lists.employee_ids, yearmonth: _this.yearmonth, branch_id: _this.viewing_branch});
          // _this.m.setIsOpenShiftManipulateDialog(true);

          if (origin_dataset.row != dest_dataset.row || origin_dataset.col != dest_dataset.col) {
            console.log("list_delete", { row: origin_dataset.row, col: origin_dataset.col }, "to", {
              row: dest_dataset.row,
              col: dest_dataset.col,
            });
            await _this.m.manipulateDeleteShift({
              origin_row: origin_dataset.row,
              origin_col: origin_dataset.col,
              dest_row: dest_dataset.row,
              dest_col: dest_dataset.col,
              employee_ids: _this.lists.employee_ids,
              yearmonth: _this.yearmonth,
              branch_id: _this.viewing_branch,
            });
          }
          $(".shift_block, .no_shift_block").removeClass("manipulate_hover");
          $(".shift_block, .no_shift_block").removeClass("hover_source");
        },
        cancelDrag() {
          $(".shift_block, .no_shift_block").removeClass("manipulate_hover");
          $(".shift_block, .no_shift_block").removeClass("hover_source");
        },
        mouseenterDrag(origin_dataset, dragging_dataset, hover_dataset) {
          let selector = "";

          const startrow = Math.min(origin_dataset.row, hover_dataset.row);
          const startcol = Math.min(origin_dataset.col, hover_dataset.col);
          const endrow = Math.max(origin_dataset.row, hover_dataset.row);
          const endcol = Math.max(origin_dataset.col, hover_dataset.col);

          for (let nowrow = startrow; nowrow <= endrow; nowrow++) {
            for (let nowcol = startcol; nowcol <= endcol; nowcol++) {
              if (selector == "") {
                selector += `.autofill-${nowrow}-${nowcol}`;
              } else {
                selector += `,.autofill-${nowrow}-${nowcol}`;
              }
            }
          }

          $(".shift_block, .no_shift_block").removeClass("manipulate_hover");
          $(selector).addClass("manipulate_hover");
        },
        mouseleaveDrag(origin_dataset, dragging_dataset, hover_dataset) {},
      };

      const shift_drag = {
        startDrag(dataset) {
          if (_this.editable_shift) {
            _this.m.setDragShiftId(dataset.shift_id);
          }
        },
        async endDrag(origin_dataset, dragging_dataset, dest_dataset) {
          if (origin_dataset.row != dest_dataset.row || origin_dataset.col != dest_dataset.col) {
            if (_this.m.drag_shift_id !== null) {
              await _this.m.dragListShift({
                origin_row: origin_dataset.row,
                origin_col: origin_dataset.col,
                dest_row: dest_dataset.row,
                dest_col: dest_dataset.col,
                employee_ids: _this.lists.employee_ids,
                yearmonth: _this.yearmonth,
                branch_id: _this.viewing_branch,
                is_shift: dest_dataset.is_shift,
              });
            }
          }
        },
        cancelDrag() {},
        mouseenterDrag(origin_dataset, dragging_dataset, hover_dataset) {},
        mouseleaveDrag(origin_dataset, dragging_dataset, hover_dataset) {},
      };

      const pattern_drag = {
        startDrag(dataset) {
          if (_this.editable_shift) {
            _this.m.setDragShiftPatternId(dataset.shift_pattern_id);
          }
        },
        async endDrag(origin_dataset, dragging_dataset, dest_dataset) {
          if (origin_dataset.row == dest_dataset.row && origin_dataset.col == dest_dataset.col)
            return;
          if (_this.m.drag_shift_pattern_id !== null) {
            await _this.m.dragShiftPatternShift({
              shift_pattern_id: origin_dataset.shift_pattern_id,
              date: dest_dataset.date,
              employee_id: _this.lists.employee_ids[dest_dataset.row],
              yearmonth: _this.yearmonth,
              branch_id: _this.viewing_branch,
              is_shift: dest_dataset.is_shift,
            });
          }
        },
        cancelDrag() {},
        mouseenterDrag(origin_dataset, dragging_dataset, hover_dataset) {},
        mouseleaveDrag(origin_dataset, dragging_dataset, hover_dataset) {},
      };

      const right_click = {
        rightClick(dataset) {
          _this.m.setListRightClick({
            date: dataset.date,
            employee_id: dataset.employee_id,
            shift_id: dataset.shift_id,
          });
        },
      };

      dragdrop(shift_autofill, "shift_autofill");
      dragdrop(shift_manipulate, "shift_manipulate");
      dragdrop(shift_drag, "shift_drag");
      dragdrop(pattern_drag, "pattern_drag");
      dragdrop(right_click, "shift_menu");
    }

    get employees() {
      return this.monthly_list.employees;
    }
    get branches() {
      return this.monthly_list.branches;
    }
    get lists() {
      let i = 1;
      const lists = [];

      const lists_origin = util.deep_copy(this.monthly_list.lists);

      const employee_ids = Object.keys(lists_origin);

      if (this.sort_name) {
        employee_ids.sort((x, y) =>
          util.sort_idx(this.employees[x].name, this.employees[y].name, this.sort_name),
        );
      } else if (this.sort_code) {
        employee_ids.sort((x, y) =>
          util.sort_idx(this.employees[x].code, this.employees[y].code, this.sort_code),
        );
      } else if (this.sort_branch) {
        employee_ids.sort((x, y) =>
          util.sort_idx(this.employees[x].branch_id, this.employees[y].branch_id, this.sort_branch),
        );
      } else {
        employee_ids.sort((x, y) =>
          util.sort_idx(this.employees[x].code, this.employees[y].code, "asc"),
        );
        employee_ids.sort((x, y) =>
          util.sort_idx(this.employees[x].branch_id, this.employees[y].branch_id, "asc"),
        );
        employee_ids.sort((x, y) =>
          util.sort_idx(
            this.employees[x].branch_id == this.viewing_branch ? 0 : 1,
            this.employees[y].branch_id == this.viewing_branch ? 0 : 1,
            "asc",
          ),
        );
      }

      const employee_hash = {};

      employee_ids.forEach((employee_id) => {
        lists.push({ ...this.monthly_list.lists[employee_id], employee_id, index: i });
        employee_hash[i] = employee_id;
        i++;
      });
      return { lists, employee_ids: employee_hash };
    }

    get shift_patterns() {
      return this.monthly_list.shift_patterns;
    }
    get date_array() {
      return util.getMonthDateArrayNationalHoliday(
        this.header.national_holidays,
        util.date2yearmonth(this.date),
      );
    }

    open_chart(date) {
      this.date = date;
      this.m.setIsOpenChartDialog(true);
    }

    get cell_size() {
      const cell_size = 30; //ここに加えて、scss変数($cell_size(frozentableの引数に渡しているやつ))も変更する！
      const table_size = 230 + cell_size * 31;
      return { "--cell_size": `${cell_size}px`, "--table_size": `${table_size}px` };
    }

    setDate(date: Date) {
      this.date = util.formatDate(date, "yyyy-mm-dd");
      this.m.setIsOpenShiftDialog(true);
    }

    editShift() {
      this.setDate(util.date2obj(this.m.list_right_click.date));
      this.m.cancelAll();
      this.m.editShift(this.m.list_right_click.shift_id);
    }

    deleteShift() {
      this.m.deleteListShift({
        branch_id: this.viewing_branch,
        employee_id: this.m.list_right_click.employee_id,
        date: this.m.list_right_click.date,
        shift_id: this.m.list_right_click.shift_id,
      });
    }
  }
