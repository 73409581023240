
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import shiftbundle_global from "@/vuex/shift/shiftbundle_global";
  import Need from "@/typesold/need";
  import need_manager from "@/vuex/shift/need_manager";
  import realtime from "@/vuex/shift/realtime";
  import UtilPopup from "@/components/util/Popup.vue";
  import ShiftDialogShiftpatternControlbutton from "@/components/shift/dialog/shiftpattern/Controlbutton.vue";
  import ShiftDialogShiftpatternInputrow from "@/components/shift/dialog/shiftpattern/Inputrow.vue";
  import ShiftDialogShiftpatternNormalrow from "@/components/shift/dialog/shiftpattern/Normalrow.vue";
  import ShiftDialogShiftpatternNoshiftpatternrow from "@/components/shift/dialog/shiftpattern/Noshiftpatternrow.vue";
  import ShiftDialogShiftpatternColorpicker from "@/components/shift/dialog/shiftpattern/Colorpicker.vue";

  @Component({
    components: {
      UtilPopup,
      ShiftDialogShiftpatternControlbutton,
      ShiftDialogShiftpatternInputrow,
      ShiftDialogShiftpatternNormalrow,
      ShiftDialogShiftpatternNoshiftpatternrow,
      ShiftDialogShiftpatternColorpicker,
    },
  })
  export default class Main extends Mixins(mixinShift) {
    get exclude_colors() {
      return this.shift_patterns.map((shift_pattern) => shift_pattern.color);
    }
    get shift_patterns() {
      return this.shiftbundle_global.shift_patterns;
    }

    get is_display() {
      return this.sm.is_open_shift_pattern_dialog;
    }
    set is_display(val: boolean) {
      this.sm.cancelAll();
      this.sm.setIsOpenShiftPatternDialog(val);
    }
  }
