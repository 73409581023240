
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import dialog from "@/vuex/dialog";
  import ShiftCalendarHeaderMonthchanger from "@/components/shift/calendar/header/Monthchanger.vue";
  import ShiftCalendarHeaderBranchselector from "@/components/shift/calendar/header/Branchselector.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      ShiftCalendarHeaderMonthchanger,
      ShiftCalendarHeaderBranchselector,
      CommonButton,
    },
  })
  export default class Calendar extends Mixins(mixinShift) {}
