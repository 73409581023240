
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonProgress from "@/components/common/Progress.vue";
  import CommonPager from "@/components/common/Pager.vue";
  import ShiftDialogDailysummeryPagerHead from "@/components/shift/dialog/dailysummery/pager/Head.vue";
  import ShiftDialogDailysummerySummerytable from "@/components/shift/dialog/dailysummery/Summerytable.vue";
  import ShiftDialogDailysummeryPagerFoot from "@/components/shift/dialog/dailysummery/pager/Foot.vue";

  @Component({
    components: {
      UtilPopup,
      CommonProgress,
      CommonPager,
      ShiftDialogDailysummeryPagerHead,
      ShiftDialogDailysummerySummerytable,
      ShiftDialogDailysummeryPagerFoot,
    },
  })
  export default class Main extends Mixins(mixinShift) {
    prev_date() {
      const dateobj = util.date2obj(`${this.date}`);
      dateobj.setDate(dateobj.getDate() - 1);
      this.date = util.formatDate(dateobj, "yyyy-mm-dd");
    }
    next_date() {
      const dateobj = util.date2obj(`${this.date}`);
      dateobj.setDate(dateobj.getDate() + 1);
      this.date = util.formatDate(dateobj, "yyyy-mm-dd");
    }

    get is_display() {
      return this.m.is_open_daily_summery_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenDailySummeryDialog(val);
    }
  }
