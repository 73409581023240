
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import global from "@/vuex/shift/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonText from "@/components/common/Text.vue";

  @Component({
    components: {
      CommonSelect,
      CommonText,
    },
  })
  export default class Head extends Mixins(mixinShift) {
    disp_num_option = { 3: 3, 5: 5, 10: 10, 30: 30, 50: 50, 100: 100 };

    created() {
      const disp_num = Number(util.getLocalStorage("summery_disp_num"));
      console.log(disp_num);
      this.disp_num = disp_num ? Number(disp_num) : 10;
      console.log(this.disp_num);
    }
  }
