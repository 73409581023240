
  import { Component, Mixins } from "vue-property-decorator";
  import mixinShift from "@/mixins/mixinShift";

  @Component({
    components: {},
  })
  export default class Foot extends Mixins(mixinShift) {
    get all_index() {
      return this.searched_sorted_daily_summery.length;
    }
    get start_index() {
      return this.daily_summery_disp_num * (this.daily_summery_page - 1) + 1;
    }
    get end_index() {
      return Math.min(this.all_index, this.daily_summery_disp_num * this.daily_summery_page);
    }
    get max_page() {
      return Math.ceil(this.all_index / this.daily_summery_disp_num);
    }
    get page_num_list() {
      let start_page = Math.max(1, this.daily_summery_page - 2);
      if (this.daily_summery_page > this.max_page - 2) start_page = Math.max(1, this.max_page - 4);
      let end_page = Math.min(this.max_page, this.daily_summery_page + 2);
      if (this.daily_summery_page < 3) end_page = Math.min(this.max_page, 5);
      const page_num_list = [];
      for (let i = start_page; i <= end_page; i++) {
        page_num_list.push(i);
      }
      return page_num_list;
    }
  }
