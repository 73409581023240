
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import global from "@/vuex/shift/global";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import Shift_pattern from "@/typesold/shift_pattern";
  import Need_pattern from "@/typesold/need_pattern";
  import CommonText from "@/components/common/Text.vue";
  import CommonTime from "@/components/common/Time.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonText,
      CommonTime,
      CommonButton,
    },
  })
  export default class Inputrow extends Mixins(mixinShift) {
    @Prop() editshift_pattern: Shift_pattern;
    @Prop() exclude_colors: any;

    shift_pattern: Shift_pattern = {
      id: null,
      branch_id: null,
      name: null,
      color: null,
      shift_pattern_shift: [],
      shift_pattern_break: [],
    };

    @Watch("tmp_color")
    onChangeTmpColor(val, oldval) {
      if (
        this.exclude_colors.includes(val) &&
        !(this.editshift_pattern && this.editshift_pattern.color == val)
      ) {
        this.m.setColor(oldval);
        this.shift_pattern.color = oldval;
        dialog.openErrorDialog({
          title: "エラー",
          error: ["この色はすでに他のパターンで使用されています"],
        });
      } else {
        this.shift_pattern.color = val;
      }
    }

    get tmp_color() {
      return this.m.color;
    }

    created() {
      if (this.editshift_pattern) {
        this.shift_pattern = util.deep_copy(this.editshift_pattern);
        this.m.setColor(this.shift_pattern.color);
      } else {
        this.shift_pattern = {
          id: null,
          branch_id: null,
          name: null,
          color: null,
          shift_pattern_shift: [],
          shift_pattern_break: [],
        };
        this.m.setColor(this.shift_pattern.color);
      }
      this.shift_pattern_shift_input();
      this.shift_pattern_break_input();
    }

    create_shift_pattern() {
      this.sm.createShiftPattern({
        branch_id: this.viewing_branch,
        shift_pattern: this.shift_pattern,
      });
    }
    update_shift_pattern() {
      this.sm.updateShiftPattern({
        branch_id: this.viewing_branch,
        shift_pattern_id: this.sm.edit_shift_pattern_flag,
        shift_pattern: this.shift_pattern,
      });
    }

    get is_edit() {
      return this.editshift_pattern;
    }
    get is_add() {
      return !this.editshift_pattern;
    }

    get max_row(): number {
      return Math.max(
        this.shift_pattern.shift_pattern_shift.length,
        this.shift_pattern.shift_pattern_break.length,
      );
    }

    shift_pattern_shift_input() {
      this.shift_pattern.shift_pattern_shift = this.time_input(
        this.shift_pattern.shift_pattern_shift,
      );
    }
    shift_pattern_break_input() {
      this.shift_pattern.shift_pattern_break = this.time_input(
        this.shift_pattern.shift_pattern_break,
      );
    }

    time_input(shift_pattern_times) {
      const length = shift_pattern_times.length - 1;
      shift_pattern_times = shift_pattern_times.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (shift_pattern_times.length == 0) {
        shift_pattern_times.push({
          id: null,
          shift_pattern_id: null,
          started_at: null,
          ended_at: null,
        });
      } else {
        const shift_pattern_time = shift_pattern_times[shift_pattern_times.length - 1];
        if (shift_pattern_time.started_at != null || shift_pattern_time.ended_at != null) {
          shift_pattern_times.push({
            id: null,
            shift_pattern_id: null,
            started_at: null,
            ended_at: null,
          });
        }
      }
      return shift_pattern_times;
    }

    openColorpicker() {
      this.m.setIsOpenShiftPatternColorpickerDialog(true);
    }
  }
