
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import monthly from "@/vuex/shift/monthly";
import daily from "@/vuex/shift/daily";
import dialog from "@/vuex/dialog";

@Component({
  components: {
  }
})
export default class Noshiftrow extends Mixins(utilMixins) {
  @Prop() shift;
  get colspan() {
    return this.s_auth == 0 ? 6 : 7;
  }
}
