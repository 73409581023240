
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import global from "@/vuex/shift/global";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import Shift_pattern from "@/typesold/shift_pattern";
  import Need_pattern from "@/typesold/need_pattern";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonButton,
    },
  })
  export default class Normalrow extends Mixins(mixinShift) {
    @Prop() shift_pattern: Shift_pattern;

    get max_row(): number {
      return Math.max(
        this.shift_pattern.shift_pattern_shift.length,
        this.shift_pattern.shift_pattern_break.length,
      );
    }

    delete_shift_pattern(shift_pattern_id) {
      this.sm.deleteShiftPattern({ branch_id: this.viewing_branch, shift_pattern_id });
    }

    get bgcolor() {
      return { "--background-color": this.shift_pattern.color ?? "606060" };
    }
  }
