
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import daily from "@/vuex/shift/daily";
  import monthly from "@/vuex/shift/monthly";
  import dialog from "@/vuex/dialog";
  import { BranchHeaderData } from "@/typesold/header";

  @Component({
    components: {},
  })
  export default class Normalrow extends Mixins(mixinShift) {
    @Prop() shift;
    @Prop() employee_hash;

    get show_gps() {
      const header = this.header as BranchHeaderData;
      return (
        this.s_auth == 2 ||
        (this.employee &&
          ((this.employee.hiring_type == 0 && header.auth_attendance_gps_arbeit == 1) ||
            (this.employee.hiring_type == 1 && header.auth_attendance_gps_worker == 1) ||
            (this.employee.hiring_type == 2 && header.auth_attendance_gps_pres == 1) ||
            (this.employee.hiring_type == 3 && header.auth_attendance_gps_other == 1)))
      );
    }

    get employee() {
      return this.employee_hash[this.shift.employee_id];
    }

    get max_row() {
      return Math.max(
        this.shift.shift_shift.length,
        this.shift.shift_punch.length,
        this.shift.shift_break.length,
      );
    }
  }
