
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import global from "@/vuex/shift/global";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import shiftbundle_manager from "@/vuex/shift/shiftbundle_manager";
  import shiftbundle_global from "@/vuex/shift/shiftbundle_global";
  import Shift from "@/typesold/shift";
  import Shift_pattern from "@/typesold/shift_pattern";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonTime from "@/components/common/Time.vue";

  @Component({
    components: {
      CommonSelect,
      CommonTime,
    },
  })
  export default class Inputrow extends Mixins(mixinShift) {
    @Prop() dateprop: string;
    @Prop() dayprop: number;
    @Prop() is_holiday: boolean;

    get shift_pattern_value() {
      return null;
    }
    set shift_pattern_value(val) {
      if (val == 0) {
        this.shift = {
          id: null,
          branch_id: this.viewing_branch,
          employee_id: null,
          date: null,
          shift_shift: [
            {
              id: null,
              shift_id: null,
              started_at: null,
              ended_at: null,
            },
          ],
          shift_punch: [],
          shift_break: [
            {
              id: null,
              shift_id: null,
              started_at: null,
              ended_at: null,
            },
          ],
        };
      } else {
        this.shift = this.convert_pattern_shift(
          this.shiftbundle_global.shift_patterns.find((shift_pattern) => shift_pattern.id == val),
        );
      }
    }

    convert_pattern_shift(shift_pattern: Shift_pattern) {
      const shift: Shift = {
        id: null,
        branch_id: this.viewing_branch,
        employee_id: null,
        date: this.dateprop,
        shift_shift: shift_pattern.shift_pattern_shift.map((time) => {
          return {
            id: 0,
            shift_id: 0,
            started_at: time.started_at,
            ended_at: time.ended_at,
          };
        }),
        shift_punch: [],
        shift_break: shift_pattern.shift_pattern_break.map((time) => {
          return {
            id: 0,
            shift_id: 0,
            started_at: time.started_at,
            ended_at: time.ended_at,
          };
        }),
      };
      return shift;
    }

    get datestr() {
      const dateobj = util.date2obj(this.dateprop);
      return util.formatDate(dateobj, "mm月dd日");
    }
    get daystr() {
      return util.day2weekstr(this.dayprop);
    }

    get shift() {
      return shiftbundle_manager.shifts[this.dateprop];
    }
    set shift(val) {
      shiftbundle_manager.setShift({ date: this.dateprop, shift: val });
    }

    get max_row(): number {
      if (this.shift) {
        this.shift_input();
        this.break_input();
      }
      return Math.max(this.shift.shift_shift.length, this.shift.shift_break.length);
    }

    get shift_pattern_option() {
      return this.shiftbundle_global.shift_pattern_option;
    }

    shift_input() {
      this.shift.shift_shift = this.time_input(this.shift.shift_shift);
    }
    break_input() {
      this.shift.shift_break = this.time_input(this.shift.shift_break);
    }

    time_input(shift_times) {
      const length = shift_times.length - 1;
      shift_times = shift_times.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (shift_times.length == 0) {
        shift_times.push({ id: null, shift_id: null, started_at: null, ended_at: null });
      } else {
        const shift_time = shift_times[shift_times.length - 1];
        if (shift_time.started_at != null || shift_time.ended_at != null) {
          shift_times.push({ id: null, shift_id: null, started_at: null, ended_at: null });
        }
      }
      return shift_times;
    }
  }
