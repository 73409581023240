import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=6b076f16&scoped=true&lang=pug"
import script from "./Main.vue?vue&type=script&lang=ts"
export * from "./Main.vue?vue&type=script&lang=ts"
import style0 from "./Main.vue?vue&type=style&index=0&id=6b076f16&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b076f16",
  null
  
)

export default component.exports