
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import monthly from "@/vuex/shift/monthly";
import daily from "@/vuex/shift/daily";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import realtime from "@/vuex/shift/realtime";
import UtilPopup from "@/components/util/Popup.vue";
import { Tooltip } from "ant-design-vue";

@Component({
  components: {
    UtilPopup,
    Tooltip,
  }
})
export default class Main extends Mixins(mixinShift) {
  chart = null;

  get chart_cols() {
    return Math.floor((Number(this.chart.col_num) / Number(this.chart.division_col)));
  }

  get is_display() {
    if (this.m.is_open_chart_dialog)
      this.get_chart();
    return this.m.is_open_chart_dialog;
  }
  set is_display(val: boolean) {
    if (!val)
      this.chart = null;
    this.m.setIsOpenChartDialog(val);
  }

  async get_chart() {
    this.chart = await realtime.getChart({date: this.date, branch_id: this.viewing_branch});
  }

  get datestr() {
    return util.formatDate(util.date2obj(this.date), 'mm月dd日');
  }

  get employee_hash() {
    if (this.daily) {
      return this.daily.employee_hash;
    }
  }
}
