
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinShift from '@/mixins/mixinShift';
import UtilPopup from "@/components/util/Popup.vue";
import ShiftMain from "@/components/shift/Main.vue";

@Component({
  components: {
    UtilPopup,
    ShiftMain,
  }
})
export default class Dialog extends Mixins(mixinShift) {
  get is_display() {
    return this.m.is_open_shift_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenShiftDialog(val);
  }
}
