
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import dialog from "@/vuex/dialog";
  import CommonTab from "@/components/common/Tab.vue";
  import ShiftCalendarMain from "@/components/shift/calendar/Main.vue";
  import ShiftMain from "@/components/shift/Main.vue";
  import ShiftDialogShiftDialog from "@/components/shift/dialog/shift/Dialog.vue";

  @Component({
    components: {
      CommonTab,
      ShiftCalendarMain,
      ShiftMain,
      ShiftDialogShiftDialog,
    },
  })
  export default class Shift extends Mixins(mixinShift) {}
