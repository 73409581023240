
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonButton,
    },
  })
  export default class Controlbutton extends Mixins(mixinShift) {
    created() {
      let mode = Number(util.getLocalStorage("shift_mode"));
      if (mode === null) {
        mode = 0;
      }
      this.m.setMode(mode);
    }

    get mode_value() {
      return this.mode;
    }
    set mode_value(val) {
      if (val) {
        this.mode = 1;
      } else {
        this.mode = 0;
      }
    }
  }
