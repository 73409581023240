
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import ShiftShiftShiftMain from "@/components/shift/shift/shift/Main.vue";
  import ShiftShiftAbleMain from "@/components/shift/shift/able/Main.vue";

  @Component({
    components: {
      ShiftShiftShiftMain,
      ShiftShiftAbleMain,
    },
  })
  export default class Main extends Mixins(mixinShift) {
    get shifts() {
      return this.daily.shifts;
    }
    get managed_shifts() {
      let shifts = util.deep_copy(this.shifts);
      shifts = this.sort_shift(shifts);
      return shifts;
    }
    get ables() {
      return this.daily.ables;
    }
    get employee_hash() {
      return this.daily.employee_hash;
    }
    get shift_memo() {
      if (this.daily) {
        if (this.daily.shift_memo) {
          return this.daily.shift_memo.content;
        } else {
          return "";
        }
      }
    }
  }
