
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import monthly from "@/vuex/shift/monthly";
import daily from "@/vuex/shift/daily";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import dragdrop from "dragdrop";
import ShiftShiftShiftOtherrow from "@/components/shift/shift/shift/Otherrow.vue";

@Component({
  components: {
    ShiftShiftShiftOtherrow,
  }
})
export default class Main extends Mixins(mixinShift) {
  @Prop() shifts;
  @Prop() employee_hash;
}
