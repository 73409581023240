
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dragdrop from "dragdrop";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import ShiftShiftShiftSelfrow from "@/components/shift/shift/shift/Selfrow.vue";

  @Component({
    components: {
      ShiftShiftShiftSelfrow,
    },
  })
  export default class Main extends Mixins(mixinShift) {
    @Prop() shift;
    @Prop() employee_hash;
  }
