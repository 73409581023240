
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import global from "@/vuex/shift/global";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import Need_pattern from "@/typesold/need_pattern";
  import CommonText from "@/components/common/Text.vue";
  import CommonTime from "@/components/common/Time.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonText,
      CommonTime,
      CommonButton,
    },
  })
  export default class Inputrow extends Mixins(mixinShift) {
    @Prop() editneed_pattern: Need_pattern;

    need_pattern: Need_pattern = {
      id: null,
      branch_id: null,
      name: null,
      need_pattern_time: [],
    };

    created() {
      if (this.editneed_pattern) {
        this.need_pattern = util.deep_copy(this.editneed_pattern);
      } else {
        this.need_pattern = {
          id: null,
          branch_id: null,
          name: null,
          need_pattern_time: [],
        };
      }
      this.need_pattern_input();
    }

    create_need_pattern() {
      this.nm.createNeedPattern({
        branch_id: this.viewing_branch,
        need_pattern: this.need_pattern,
      });
    }
    update_need_pattern() {
      this.nm.updateNeedPattern({
        branch_id: this.viewing_branch,
        need_pattern_id: this.nm.edit_need_pattern_flag,
        need_pattern: this.need_pattern,
      });
    }

    get is_edit() {
      return this.editneed_pattern;
    }
    get is_add() {
      return !this.editneed_pattern;
    }

    get max_row(): number {
      return this.need_pattern.need_pattern_time.length;
    }

    need_pattern_input() {
      this.need_pattern.need_pattern_time = this.time_input(this.need_pattern.need_pattern_time);
    }

    time_input(need_pattern_times) {
      const length = need_pattern_times.length - 1;
      need_pattern_times = need_pattern_times.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (need_pattern_times.length == 0) {
        need_pattern_times.push({
          id: null,
          need_pattern_id: null,
          started_at: null,
          ended_at: null,
          person_num: null,
        });
      } else {
        const need_pattern_time = need_pattern_times[need_pattern_times.length - 1];
        if (need_pattern_time.started_at != null || need_pattern_time.ended_at != null) {
          need_pattern_times.push({
            id: null,
            need_pattern_id: null,
            started_at: null,
            ended_at: null,
            person_num: null,
          });
        }
      }
      return need_pattern_times;
    }
  }
