
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import dialog from "@/vuex/dialog";
  import monthly from "@/vuex/shift/monthly";
  import manager from "@/vuex/shift/manager";
  import ShiftCalendarHeaderMain from "@/components/shift/calendar/header/Main.vue";
  import CommonProgress from "@/components/common/Progress.vue";
  import ShiftCalendarFooterMain from "@/components/shift/calendar/footer/Main.vue";

  @Component({
    components: {
      ShiftCalendarHeaderMain,
      CommonProgress,
      ShiftCalendarFooterMain,
    },
  })
  export default class Main extends Mixins(mixinShift) {
    get disp_need_error() {
      return manager.disp_need_error;
    }

    is_same_date(date1, date2) {
      if (date1.getFullYear() == date2.getFullYear()) {
        if (date1.getMonth() == date2.getMonth()) {
          if (date1.getDate() == date2.getDate()) {
            return true;
          }
        }
      }
      return false;
    }
    is_same_month(date1, date2) {
      if (date1.getFullYear() == date2.getFullYear()) {
        if (date1.getMonth() == date2.getMonth()) {
          return true;
        }
      }
      return false;
    }

    get calendar_data() {
      const dateobj = this.dateobj;
      const startdateobj = new Date(this.dateobj);
      startdateobj.setDate(1);

      //初めの週を日曜まで戻す
      for (let i = startdateobj.getDay(); i > 0; i--) {
        startdateobj.setDate(startdateobj.getDate() - 1);
      }

      const calendar_data = [];

      for (;;) {
        const calendar_week = [];
        for (let j = 0; j < 7; j++) {
          if (this.is_manager)
            calendar_week.push({
              date: new Date(startdateobj),
              is_error:
                this.monthly.need_error.indexOf(util.formatDate(startdateobj, "yyyy-mm-dd")) !== -1,
            });
          else
            calendar_week.push({
              date: new Date(startdateobj),
              shifts: this.monthly_employee.shifts[util.formatDate(startdateobj, "yyyy-mm-dd")],
            });
          startdateobj.setDate(startdateobj.getDate() + 1);
        }
        calendar_data.push(calendar_week);
        if (startdateobj.getMonth() != dateobj.getMonth()) {
          break;
        }
      }

      return calendar_data;
    }

    setDate(date: Date) {
      this.date = util.formatDate(date, "yyyy-mm-dd");
      this.m.setIsOpenShiftDialog(true);
    }
  }
