
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import dialog from "@/vuex/dialog";
  import ShiftShiftHeaderDatechanger from "@/components/shift/shift/header/Datechanger.vue";
  import ShiftShiftHeaderControlbutton from "@/components/shift/shift/header/Controlbutton.vue";

  @Component({
    components: {
      ShiftShiftHeaderDatechanger,
      ShiftShiftHeaderControlbutton,
    },
  })
  export default class Calendar extends Mixins(mixinShift) {}
