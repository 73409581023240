
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import global from "@/vuex/shift/global";
import mixinShift from '@/mixins/mixinShift';
import UtilPopup from "@/components/util/Popup.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    UtilPopup,
    CommonButton,
  }
})
export default class Main extends Mixins(mixinShift) {
  get is_display() {
    return this.m.is_open_shift_table_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenShiftTableDialog(val);
  }

  get viewing_branch_name() {
    return '各店舗';
  }

  download_daily_shifttable_csv() {
    this.m.downloadDailyShiftTableCsv({branch_id: this.viewing_branch, branch_name: this.viewing_branch_name, date: this.date})
  }
  download_weekly_shifttable_csv() {
    this.m.downloadWeeklyShiftTableCsv({branch_id: this.viewing_branch, branch_name: this.viewing_branch_name, date: this.date})
  }
  download_monthly_shifttable_csv() {
    this.m.downloadMonthlyShiftTableCsv({branch_id: this.viewing_branch, branch_name: this.viewing_branch_name, date: this.date})
  }
  download_daily_all_shifttable_csv() {
    this.m.downloadDailyAllShiftTableCsv({date: this.date})
  }
  download_weekly_all_shifttable_csv() {
    this.m.downloadWeeklyAllShiftTableCsv({date: this.date})
  }
  download_monthly_all_shifttable_csv() {
    this.m.downloadMonthlyAllShiftTableCsv({date: this.date})
  }
}
