
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import monthly from "@/vuex/shift/monthly";
import daily from "@/vuex/shift/daily";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import { VExpansionPanel } from "vuetify/lib";
import { VExpansionPanelContent } from "vuetify/lib";
import ShiftShiftAbleNormalrow from "@/components/shift/shift/able/Normalrow.vue";
import ShiftShiftAbleNoablerow from "@/components/shift/shift/able/Noablerow.vue";

@Component({
  components: {
    VExpansionPanel,
    VExpansionPanelContent,
    ShiftShiftAbleNormalrow,
    ShiftShiftAbleNoablerow,
  }
})
export default class Main extends Mixins(mixinShift) {
  disp_able: number = 0;

  @Prop() ables;
  @Prop() shifts;
  
  created() {
    this.disp_able = Number(util.getLocalStorage('disp_able'));
  }
  @Watch('disp_able')
  on_change_disp_able(val) {
    util.setLocalStorage('disp_able', val);
  }
}
