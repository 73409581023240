
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import dialog from "@/vuex/dialog";
import global from "@/vuex/shift/global";
import manager from "@/vuex/shift/manager";
import need_manager from "@/vuex/shift/need_manager";
import need_global from "@/vuex/shift/need_global";
import ShiftDialogNeedInputrow from "@/components/shift/dialog/need/Inputrow.vue";
import CommonProgress from "@/components/common/Progress.vue";

@Component({
  components: {
    ShiftDialogNeedInputrow,
    CommonProgress,
  }
})
export default class Needtable extends Mixins(mixinShift) {
  get is_valid() {
    return need_manager.is_valid;
  }

  get date_array_with_holiday() {
    return util.getMonthDateArrayNationalHoliday(this.header.national_holidays, this.need_date)
  }
}
