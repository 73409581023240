
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import CommonCheck from "@/components/common/Check.vue";

@Component({
  components: {
    CommonCheck,
  }
})
export default class Errorcheck extends Mixins(mixinShift) {
  get disp_need_error() {
    return manager.disp_need_error;
  }
  set disp_need_error(val) {
    util.setLocalStorage('disp_need_error', val);
    manager.setDispNeedError(val);
  }
  created() {
    let disp_need_error = util.getLocalStorage('disp_need_error');
    this.disp_need_error = disp_need_error ? Boolean(disp_need_error) : false;
  }
}
