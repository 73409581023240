
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import need_global from "@/vuex/shift/need_global";
  import need_manager from "@/vuex/shift/need_manager";
  import Need from "@/typesold/need";
  import Need_pattern from "@/typesold/need_pattern";
  import CommonTime from "@/components/common/Time.vue";
  import CommonText from "@/components/common/Text.vue";
  import CommonCheck from "@/components/common/Check.vue";
  import CommonSelect from "@/components/common/Select.vue";

  @Component({
    components: {
      CommonTime,
      CommonText,
      CommonSelect,
      CommonCheck,
    },
  })
  export default class Needcontrol extends Mixins(mixinShift) {
    get need_pattern_value() {
      return null;
    }
    set need_pattern_value(val) {
      if (val == 0) {
        this.need = {
          id: null,
          branch_id: this.viewing_branch,
          date: null,
          need_time: [
            {
              id: null,
              need_id: null,
              started_at: null,
              ended_at: null,
              person_num: null,
            },
          ],
        };
      } else {
        this.need = this.convert_pattern_need(
          this.need_global.need_patterns.find((need_pattern) => need_pattern.id == val),
        );
        this.need_input();
      }
    }

    get max_row() {
      return this.need.need_time.length;
    }

    convert_pattern_need(need_pattern: Need_pattern) {
      const need: Need = {
        id: null,
        branch_id: this.viewing_branch,
        date: null,
        need_time: need_pattern.need_pattern_time.map((time) => {
          return {
            id: 0,
            need_id: 0,
            started_at: time.started_at,
            ended_at: time.ended_at,
            person_num: time.person_num,
          };
        }),
      };
      return need;
    }

    get need() {
      return need_manager.need_control;
    }
    set need(need: Need) {
      need_manager.setNeedControl({ need });
    }

    get days() {
      return need_manager.days_control;
    }
    set days(days: number[]) {
      need_manager.setDaysControl({ days });
    }

    get need_pattern_option() {
      return this.need_global.need_pattern_option;
    }

    need_input() {
      this.need.need_time = this.time_input(this.need.need_time);
    }

    time_input(need_times) {
      const length = need_times.length - 1;
      need_times = need_times.filter(
        (timeint, j) =>
          timeint.started_at != null ||
          timeint.ended_at != null ||
          timeint.person_num != null ||
          length == j,
      );
      if (need_times.length == 0) {
        need_times.push({
          id: null,
          need_id: null,
          started_at: null,
          ended_at: null,
          person_num: null,
        });
      } else {
        const need_time = need_times[need_times.length - 1];
        if (need_time.started_at != null || need_time.ended_at != null) {
          need_times.push({
            id: null,
            need_id: null,
            started_at: null,
            ended_at: null,
            person_num: null,
          });
        }
      }
      return need_times;
    }
  }
