
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import monthly from "@/vuex/shift/monthly";
import daily from "@/vuex/shift/daily";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import ShiftShiftShiftSelf from "@/components/shift/shift/shift/Self.vue";
import { VExpansionPanel } from "vuetify/lib";
import { VExpansionPanelContent } from "vuetify/lib";
import ShiftShiftShiftOthers from "@/components/shift/shift/shift/Others.vue";

@Component({
  components: {
    ShiftShiftShiftSelf,
    VExpansionPanel,
    VExpansionPanelContent,
    ShiftShiftShiftOthers,
  }
})
export default class Employee extends Mixins(mixinShift) {
  get others_shifts() {
    var value = {};
    Object.keys(this.global.branch_hash).forEach(branch_id => {
      value[branch_id] = this.managed_shifts[branch_id].filter(shift => shift.employee_id != this.s_employee_id && shift.shift_shift.length != 0);
    });
    return value;
  }

  get shifts() {
    var shifts = {};
    Object.keys(this.global.branch_hash).forEach(branch_id => {
      if (this.daily_employee[branch_id]) {
        shifts[branch_id] = this.daily_employee[branch_id].shifts;
      }
    });
    return shifts;
  }
  get managed_shifts() {
    var shifts = util.deep_copy(this.shifts);
    shifts = this.sort_shift_employee(shifts);
    return shifts;
  }

  get self_shift() {
    var value = {};
    Object.keys(this.global.branch_hash).forEach(branch_id => {
      value[branch_id] = this.managed_shifts[branch_id].find(shift => shift.employee_id == this.s_employee_id);
    });
    console.log(value);
    return value;
  }
}
