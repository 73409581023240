
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import dialog from "@/vuex/dialog";
  import global from "@/vuex/shift/global";
  import manager from "@/vuex/shift/manager";
  import need_manager from "@/vuex/shift/need_manager";
  import need_global from "@/vuex/shift/need_global";
  import header from "@/vuex/header";
  import ShiftDialogDailysummeryRow from "@/components/shift/dialog/dailysummery/Row.vue";
  import ShiftDialogDailysummeryFoot from "@/components/shift/dialog/dailysummery/Foot.vue";

  @Component({
    components: {
      ShiftDialogDailysummeryRow,
      ShiftDialogDailysummeryFoot,
    },
  })
  export default class Needtable extends Mixins(mixinShift) {}
