
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dragdrop from "dragdrop";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import ShiftShiftShiftInputrow from "@/components/shift/shift/shift/Inputrow.vue";
  import ShiftShiftShiftNormalrow from "@/components/shift/shift/shift/Normalrow.vue";
  import ShiftShiftShiftNoshiftrow from "@/components/shift/shift/shift/Noshiftrow.vue";
  import ShiftShiftShiftDragging from "@/components/shift/shift/shift/Dragging.vue";

  @Component({
    components: {
      ShiftShiftShiftInputrow,
      ShiftShiftShiftNormalrow,
      ShiftShiftShiftNoshiftrow,
      ShiftShiftShiftDragging,
    },
  })
  export default class Main extends Mixins(mixinShift) {
    @Prop() branch_id: number;
    @Prop() shifts;
    @Prop() employee_hash;

    created() {
      dragdrop(this, "shiftdrag");
    }

    get branch_name() {
      return this.global.branch_hash[this.branch_id];
    }

    get editable() {
      return this.s_auth == 1 || this.s_auth == 2;
    }

    get ableshift() {
      if (this.m.able_to_shift_flag) {
        return this.daily.ables.find((able) => able.id == this.m.able_to_shift_flag);
      }
    }

    startDrag(dataset) {
      if (this.editable_shift) {
        this.m.setDragShiftId(dataset.shift_id);
      }
    }
    async endDrag(origin_dataset, dragging_dataset, dest_dataset) {
      if (this.m.drag_shift_id !== null) {
        await this.m.dragShift({
          date: util.date2obj(dest_dataset.date),
          before_date: util.date2obj(origin_dataset.date),
          branch_id: this.viewing_branch,
        });
      }
    }
    cancelDrag() {
      this.m.resetDragShiftId();
    }
    mouseenterDrag(origin_dataset, dragging_dataset, hover_dataset) {}
    mouseleaveDrag(origin_dataset, dragging_dataset, hover_dataset) {}
  }
