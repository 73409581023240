
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import monthly from "@/vuex/shift/monthly";
import daily from "@/vuex/shift/daily";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import realtime from "@/vuex/shift/realtime";
import UtilPopup from "@/components/util/Popup.vue";
import CommonProgress from "@/components/common/Progress.vue";
import CommonPager from "@/components/common/Pager.vue";
import ShiftDialogSummeryPagerHead from "@/components/shift/dialog/summery/pager/Head.vue";
import ShiftDialogSummerySummerytable from "@/components/shift/dialog/summery/Summerytable.vue";
import ShiftDialogSummeryPagerFoot from "@/components/shift/dialog/summery/pager/Foot.vue";

@Component({
  components: {
    UtilPopup,
    CommonProgress,
    CommonPager,
    ShiftDialogSummeryPagerHead,
    ShiftDialogSummerySummerytable,
    ShiftDialogSummeryPagerFoot,
  }
})
export default class Main extends Mixins(mixinShift) {
  prev_month() {
    var dateobj = util.date2obj(this.summery_date+"-01");
    dateobj.setMonth(dateobj.getMonth() - 1);
    this.summery_date = util.formatDate(dateobj, 'yyyy-mm');
  }
  next_month() {
    var dateobj = util.date2obj(this.summery_date+"-01");
    dateobj.setMonth(dateobj.getMonth() + 1);
    this.summery_date = util.formatDate(dateobj, 'yyyy-mm');
  }

  get is_display() {
    return this.m.is_open_summery_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenSummeryDialog(val);
  }
}
