
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import global from "@/vuex/shift/global";
import dialog from "@/vuex/dialog";
import CommonPager from "@/components/common/Pager.vue";

@Component({
  components: {
    CommonPager,
  }
})
export default class Monthchanger extends Mixins(mixinShift) {
  get datestr() {
    return this.filter.monthday(this.date);
  }

  prev_date() {
    var dateobj = util.date2obj(this.date);
    dateobj.setDate(dateobj.getDate() - 1);
    this.date = util.formatDate(dateobj, 'yyyy-mm-dd');
  }
  next_date() {
    var dateobj = util.date2obj(this.date);
    dateobj.setDate(dateobj.getDate() + 1);
    this.date = util.formatDate(dateobj, 'yyyy-mm-dd');
  }
}
