
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import global from "@/vuex/shift/global";
import dialog from "@/vuex/dialog";
import CommonPager from "@/components/common/Pager.vue";

@Component({
  components: {
    CommonPager,
  }
})
export default class Monthchanger extends Mixins(mixinShift) {
  get yearmonthstr() {
    return util.formatDate(this.shiftbundledateobj, 'yyyy年mm月');
  }

  prev_month() {
    var dateobj = new Date(this.shiftbundledateobj);
    dateobj.setMonth(dateobj.getMonth() - 1);
    this.shiftbundle_date = util.formatDate(dateobj, 'yyyy-mm');
  }
  next_month() {
    var dateobj = new Date(this.shiftbundledateobj);
    dateobj.setMonth(dateobj.getMonth() + 1);
    this.shiftbundle_date = util.formatDate(dateobj, 'yyyy-mm');
  }
}
