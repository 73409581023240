
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import dialog from "@/vuex/dialog";
  import ShiftShiftListHeaderMonthchanger from "@/components/shift/shift/list/header/Monthchanger.vue";
  import ShiftShiftListHeaderControlbutton from "@/components/shift/shift/list/header/Controlbutton.vue";

  @Component({
    components: {
      ShiftShiftListHeaderMonthchanger,
      ShiftShiftListHeaderControlbutton,
    },
  })
  export default class Calendar extends Mixins(mixinShift) {}
