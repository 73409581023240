
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import dialog from "@/vuex/dialog";
import global from "@/vuex/shift/global";
import manager from "@/vuex/shift/manager";
import shiftbundle_manager from "@/vuex/shift/shiftbundle_manager";
import shiftbundle_global from "@/vuex/shift/shiftbundle_global";
import header from "@/vuex/header";
import ShiftDialogShiftbundleInputrow from "@/components/shift/dialog/shiftbundle/Inputrow.vue";
import CommonProgress from "@/components/common/Progress.vue";

@Component({
  components: {
    ShiftDialogShiftbundleInputrow,
    CommonProgress,
  }
})
export default class Shiftbundletable extends Mixins(mixinShift) {
  get date_array_with_holiday() {
    return util.getMonthDateArrayNationalHoliday(this.header.national_holidays, this.shiftbundle_date)
  }
}
