
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import daily from "@/vuex/shift/daily";
  import monthly from "@/vuex/shift/monthly";
  import dialog from "@/vuex/dialog";
  import { BranchHeaderData } from "@/typesold/header";
  import CommonProgress from "@/components/common/Progress.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonProgress,
      CommonButton,
    },
  })
  export default class Normalrow extends Mixins(mixinShift) {
    @Prop() branch_id: number;
    @Prop() shift;
    @Prop() employee_hash;

    get show_gps() {
      const header = this.header as BranchHeaderData;
      return (
        this.s_auth == 2 ||
        (this.employee.hiring_type == 0 && header.auth_attendance_gps_arbeit == 1) ||
        (this.employee.hiring_type == 1 && header.auth_attendance_gps_worker == 1) ||
        (this.employee.hiring_type == 2 && header.auth_attendance_gps_pres == 1) ||
        (this.employee.hiring_type == 3 && header.auth_attendance_gps_other == 1)
      );
    }

    get max_row() {
      return Math.max(
        this.shift.shift_shift.length,
        this.shift.shift_punch.length,
        this.shift.shift_break.length,
      );
    }

    get employee() {
      return this.employee_hash[this.shift.employee_id];
    }

    get employee_month_hash() {
      if (this.monthly) {
        return this.monthly.employee_hash;
      }
    }

    get is_myshift() {
      return this.s_auth == 0 && this.shift.employee_id == this.s_employee_id;
    }

    delete_shift(shift_id, employee_id) {
      this.m.deleteShift({
        branch_id: this.branch_id,
        employee_id,
        date: this.date,
        shift_id,
      });
    }
  }
