
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import shiftbundle_global from "@/vuex/shift/shiftbundle_global";
import shiftbundle_manager from "@/vuex/shift/shiftbundle_manager";
import CommonSelect from "@/components/common/Select.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonSelect,
    CommonButton,
  }
})
export default class Errorcheck extends Mixins(mixinShift) {
  get shiftgroup_option() {
    return this.shiftbundle_global.shiftgroup_option;
  }


  get shiftgroup_id() {
    return shiftbundle_manager.shiftgroup_id;
  }
  set shiftgroup_id(val: number | null) {
    shiftbundle_manager.setShiftgroupId({shiftgroup_id: val});
  }

}
