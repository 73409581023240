
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import global from "@/vuex/shift/global";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import need_manager from "@/vuex/shift/need_manager";
  import need_global from "@/vuex/shift/need_global";
  import Need from "@/typesold/need";
  import Need_pattern from "@/typesold/need_pattern";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonTime from "@/components/common/Time.vue";
  import CommonText from "@/components/common/Text.vue";

  @Component({
    components: {
      CommonSelect,
      CommonTime,
      CommonText,
    },
  })
  export default class Inputrow extends Mixins(mixinShift) {
    @Prop() dateprop: string;
    @Prop() dayprop: number;
    @Prop() is_holiday: boolean;

    get need_pattern_value() {
      return null;
    }
    set need_pattern_value(val) {
      if (val == 0) {
        this.need = {
          id: null,
          branch_id: this.viewing_branch,
          date: null,
          need_time: [
            {
              id: null,
              need_id: null,
              started_at: null,
              ended_at: null,
              person_num: null,
            },
          ],
        };
      } else {
        this.need = this.convert_pattern_need(
          this.need_global.need_patterns.find((need_pattern) => need_pattern.id == val),
        );
      }
    }

    convert_pattern_need(need_pattern: Need_pattern) {
      const need: Need = {
        id: null,
        branch_id: this.viewing_branch,
        date: this.dateprop,
        need_time: need_pattern.need_pattern_time.map((time) => {
          return {
            id: 0,
            need_id: 0,
            started_at: time.started_at,
            ended_at: time.ended_at,
            person_num: time.person_num,
          };
        }),
      };
      return need;
    }

    get datestr() {
      const dateobj = util.date2obj(this.dateprop);
      return util.formatDate(dateobj, "mm月dd日");
    }
    get daystr() {
      return util.day2weekstr(this.dayprop);
    }

    get need() {
      return need_manager.needs[this.dateprop];
    }
    set need(val) {
      need_manager.setNeed({ date: this.dateprop, need: val });
    }

    get max_row(): number {
      if (this.need) {
        this.need_input();
      }
      return this.need.need_time.length;
    }

    get need_pattern_option() {
      return this.need_global.need_pattern_option;
    }

    need_input() {
      this.need.need_time = this.time_input(this.need.need_time);
    }

    time_input(need_times) {
      const length = need_times.length - 1;
      need_times = need_times.filter(
        (timeint, j) =>
          timeint.started_at != null ||
          timeint.ended_at != null ||
          timeint.person_num != null ||
          length == j,
      );
      if (need_times.length == 0) {
        need_times.push({
          id: null,
          need_id: null,
          started_at: null,
          ended_at: null,
          person_num: null,
        });
      } else {
        const need_time = need_times[need_times.length - 1];
        if (need_time.started_at != null || need_time.ended_at != null) {
          need_times.push({
            id: null,
            need_id: null,
            started_at: null,
            ended_at: null,
            person_num: null,
          });
        }
      }
      return need_times;
    }
  }
