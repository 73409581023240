
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import global from "@/vuex/shift/global";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import Need_pattern from "@/typesold/need_pattern";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonButton,
    },
  })
  export default class Normalrow extends Mixins(mixinShift) {
    @Prop() need_pattern: Need_pattern;

    get max_row(): number {
      return this.need_pattern.need_pattern_time.length;
    }

    delete_need_pattern(need_pattern_id) {
      this.nm.deleteNeedPattern({ branch_id: this.viewing_branch, need_pattern_id });
    }
  }
