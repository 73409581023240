
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager.ts";
  import Need from "@/typesold/need";
  import need_manager from "@/vuex/shift/need_manager";
  import realtime from "@/vuex/shift/realtime";
  import UtilPopup from "@/components/util/Popup.vue";
  import ShiftDialogNeedMonthchanger from "@/components/shift/dialog/need/Monthchanger.vue";
  import ShiftDialogNeedControlbutton from "@/components/shift/dialog/need/Controlbutton.vue";
  import ShiftDialogNeedNeedcontrol from "@/components/shift/dialog/need/Needcontrol.vue";
  import ShiftDialogNeedApplybutton from "@/components/shift/dialog/need/Applybutton.vue";
  import ShiftDialogNeedNeedtable from "@/components/shift/dialog/need/Needtable.vue";
  import CommonTextarea from "@/components/common/Textarea.vue";

  @Component({
    components: {
      UtilPopup,
      ShiftDialogNeedMonthchanger,
      ShiftDialogNeedControlbutton,
      ShiftDialogNeedNeedcontrol,
      ShiftDialogNeedApplybutton,
      ShiftDialogNeedNeedtable,
      CommonTextarea,
    },
  })
  export default class Main extends Mixins(mixinShift) {
    comment: string | null = null;
    get is_display() {
      if (this.m.is_open_need_dialog) {
        need_manager.getNeeds({ yearmonth: this.need_date, branch_id: this.viewing_branch });
      }
      return this.m.is_open_need_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenNeedDialog(val);
    }

    get yearmonthstr() {
      return util.formatDate(this.needdateobj, "yyyy年mm月");
    }

    update_needs() {
      need_manager.updateNeeds({
        yearmonth: this.need_date,
        branch_id: this.viewing_branch,
        comment: this.comment,
      });
    }
  }
