
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import {Swatches} from 'vue-color';
import UtilPopup from "@/components/util/Popup.vue";

@Component({
  components: {

    UtilPopup,


    'color-picker': Swatches
  }
})
export default class Main extends Mixins(mixinShift) {
  get plette() {
    return [
      [
        '#E03838',
        '#E66464',
        '#ED9090',
        '#F4BCBC',
      ],
      [
        '#FF6666',
        '#FF7F7F',
        '#FF9999',
        '#FFB2B2',
      ],
      [
        '#E91E63',
        '#ED4A83',
        '#F178A2',
        '#F6A6C2',
      ],
      [
        '#BA37D1',
        '#C861DB',
        '#D78AE4',
        '#E5B4EE',
      ],
      [
        '#7146C3',
        '#8F6CCF',
        '#AC93DC',
        '#CAB9E8',
      ],
      [
        '#495DBF',
        '#6F7FCC',
        '#95A0D9',
        '#BBC2E7',
      ],
      [
        '#2196F3',
        '#51AEF4',
        '#81C4F7',
        '#B1DBFA',
      ],
      [
        '#449B47',
        '#5BB75F',
        '#7FC781',
        '#A2D6A4',
      ],
      [
        '#B0BD22',
        '#CDDC39',
        '#D7E263',
        '#E2EA8E',
      ],
      [
        '#E5CE00',
        '#FFE819',
        '#FFED4C',
        '#FFF27F',
      ],
      [
        '#E58500',
        '#FF9F19',
        '#FFB44C',
        '#FFC97F',
      ],
      [
        '#A1582A',
        '#C96E35',
        '#D48B5D',
        '#DEA886',
      ],
    ];
  }
  get tmp_color() {
    return this.m.color ?? '#FFFFFF';
  }
  set tmp_color(val) {
    this.m.setColor(val);
  }

  setColor(val) {
    this.tmp_color = val.hex;
    this.is_display = false;
  }

  get is_display() {
    return this.m.is_open_shift_pattern_colorpicker_dialog;
  }
  set is_display(val: boolean) {
    this.m.cancelAll();
    this.m.setIsOpenShiftPatternColorpickerDialog(val);
  }

}
