
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import ShiftShiftShiftMain from "@/components/shift/shift/shift/Main.vue";

  @Component({
    components: {
      ShiftShiftShiftMain,
    },
  })
  export default class Employee extends Mixins(mixinShift) {
    get shifts() {
      const shifts = {};
      Object.keys(this.global.branch_hash).forEach((branch_id) => {
        if (this.daily_employee[branch_id]) {
          shifts[branch_id] = this.daily_employee[branch_id].shifts;
        }
      });
      return shifts;
    }
    get managed_shifts() {
      let shifts = util.deep_copy(this.shifts);
      shifts = this.sort_shift_employee(shifts);
      return shifts;
    }
  }
