
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import global from "@/vuex/shift/global";
import mixinShift from '@/mixins/mixinShift';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import CommonDate from "@/components/common/Date.vue";

@Component({
  components: {
    UtilPopup,
    CommonDate,
  }
})
export default class Main extends Mixins(mixinShift) {
  delete_started_on?: string = null;
  delete_ended_on?: string = null;

  get is_display() {
    return this.m.is_open_delete_shift_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenDeleteShiftDialog(val);
  }

  delete_shift_bundle() {
    this.m.deleteShiftBundle({started_on: this.delete_started_on, ended_on: this.delete_ended_on, branch_id: this.viewing_branch, branch_name: this.global.branch_hash[this.viewing_branch]});
  }
}
