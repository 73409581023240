
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import shiftbundle_manager from "@/vuex/shift/shiftbundle_manager";
import CommonLongbutton from "@/components/common/Longbutton.vue";

@Component({
  components: {
    CommonLongbutton,
  }
})
export default class Applybutton extends Mixins(mixinShift) {
  apply_shiftbundle_control() {
    shiftbundle_manager.applyControl({yearmonth: this.shiftbundle_date, national_holidays: this.header.national_holidays});
  }
}
