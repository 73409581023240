
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import dialog from "@/vuex/dialog";
  import CommonProgress from "@/components/common/Progress.vue";
  import ShiftNormal from "@/components/shift/normal.vue";
  import ShiftList from "@/components/shift/list.vue";
  import ShiftDialogChart from "@/components/shift/dialog/Chart.vue";
  import ShiftDialogNeedMain from "@/components/shift/dialog/need/Main.vue";
  import ShiftDialogNeedpatternMain from "@/components/shift/dialog/needpattern/Main.vue";
  import ShiftDialogShiftbundleMain from "@/components/shift/dialog/shiftbundle/Main.vue";
  import ShiftDialogShiftpatternMain from "@/components/shift/dialog/shiftpattern/Main.vue";
  import ShiftDialogShifttableMain from "@/components/shift/dialog/shifttable/Main.vue";
  import ShiftDialogShiftexcelMain from "@/components/shift/dialog/shiftexcel/Main.vue";
  import ShiftDialogSummeryMain from "@/components/shift/dialog/summery/Main.vue";
  import ShiftDialogDailysummeryMain from "@/components/shift/dialog/dailysummery/Main.vue";
  import ShiftDialogDeleteshiftMain from "@/components/shift/dialog/deleteshift/Main.vue";

  @Component({
    components: {
      CommonProgress,
      ShiftNormal,
      ShiftList,
      ShiftDialogChart,
      ShiftDialogNeedMain,
      ShiftDialogNeedpatternMain,
      ShiftDialogShiftbundleMain,
      ShiftDialogShiftpatternMain,
      ShiftDialogShifttableMain,
      ShiftDialogShiftexcelMain,
      ShiftDialogSummeryMain,
      ShiftDialogDailysummeryMain,
      ShiftDialogDeleteshiftMain,
    },
  })
  export default class Shift extends Mixins(mixinShift) {}
