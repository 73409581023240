
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import daily from "@/vuex/shift/daily";
import dialog from "@/vuex/dialog";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonButton,
  }
})
export default class Normalrow extends Mixins(mixinShift) {
  @Prop() able;
  @Prop() shifts;

  get max_row() {
    return Math.max(this.able.able_time.length);
  }

  get employee_hash() {
    if (this.daily) {
      return this.daily.employee_hash;
    }
  }
  get employee() {
    if (this.employee_hash) {
      return this.employee_hash[this.able.employee_id];
    }
  }

  get not_yet_registed() {
    return this.shifts.find(shift => shift.employee_id == this.able.employee_id) === undefined;
  }
}
