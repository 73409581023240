
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import global from "@/vuex/shift/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";

@Component({
  components: {
  }
})
export default class Row extends Mixins(mixinShift) {
  @Prop() each_summery;
}
