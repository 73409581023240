
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import dialog from "@/vuex/dialog";
import manager from "@/vuex/shift/manager";
import ShiftCalendarFooterErrorcheck from "@/components/shift/calendar/footer/Errorcheck.vue";

@Component({
  components: {
    ShiftCalendarFooterErrorcheck,
  }
})
export default class Controlbutton extends Mixins(mixinShift) {

}
