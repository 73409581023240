import { render, staticRenderFns } from "./Summerytable.vue?vue&type=template&id=58888961&scoped=true&lang=pug"
import script from "./Summerytable.vue?vue&type=script&lang=ts"
export * from "./Summerytable.vue?vue&type=script&lang=ts"
import style0 from "./Summerytable.vue?vue&type=style&index=0&id=58888961&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58888961",
  null
  
)

export default component.exports