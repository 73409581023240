
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";
  import need_global from "@/vuex/shift/need_global";
  import Need from "@/typesold/need";
  import need_manager from "@/vuex/shift/need_manager";
  import realtime from "@/vuex/shift/realtime";
  import UtilPopup from "@/components/util/Popup.vue";
  import ShiftDialogNeedpatternControlbutton from "@/components/shift/dialog/needpattern/Controlbutton.vue";
  import ShiftDialogNeedpatternInputrow from "@/components/shift/dialog/needpattern/Inputrow.vue";
  import ShiftDialogNeedpatternNormalrow from "@/components/shift/dialog/needpattern/Normalrow.vue";
  import ShiftDialogNeedpatternNoneedpatternrow from "@/components/shift/dialog/needpattern/Noneedpatternrow.vue";

  @Component({
    components: {
      UtilPopup,
      ShiftDialogNeedpatternControlbutton,
      ShiftDialogNeedpatternInputrow,
      ShiftDialogNeedpatternNormalrow,
      ShiftDialogNeedpatternNoneedpatternrow,
    },
  })
  export default class Main extends Mixins(mixinShift) {
    get need_patterns() {
      return this.need_global.need_patterns;
    }

    get is_display() {
      return this.nm.is_open_need_pattern_dialog;
    }
    set is_display(val: boolean) {
      this.nm.cancelAll();
      this.nm.setIsOpenNeedPatternDialog(val);
    }
  }
