
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import dialog from "@/vuex/dialog";
  import CommonProgress from "@/components/common/Progress.vue";
  import CommonTab from "@/components/common/Tab.vue";
  import ShiftShiftListHeaderMain from "@/components/shift/shift/list/header/Main.vue";
  import ShiftShiftListMain from "@/components/shift/shift/list/Main.vue";

  @Component({
    components: {
      CommonProgress,
      CommonTab,
      ShiftShiftListHeaderMain,
      ShiftShiftListMain,
    },
  })
  export default class Shift extends Mixins(mixinShift) {}
