
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinShift from "@/mixins/mixinShift";
  import global from "@/vuex/shift/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/shift/manager";

  @Component({
    components: {},
  })
  export default class Row extends Mixins(mixinShift) {
    get reg_pay_sum() {
      let reg_pay_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        reg_pay_sum += each_summery.reg_pay;
      });
      return reg_pay_sum;
    }
    get over_pay_sum() {
      let over_pay_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        over_pay_sum += each_summery.over_pay;
      });
      return over_pay_sum;
    }
    get hol_pay_sum() {
      let hol_pay_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        hol_pay_sum += each_summery.hol_pay;
      });
      return hol_pay_sum;
    }
    get mid_pay_sum() {
      let mid_pay_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        mid_pay_sum += each_summery.mid_pay;
      });
      return mid_pay_sum;
    }
    get carfare_pay_sum() {
      let carfare_pay_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        carfare_pay_sum += each_summery.carfare_pay;
      });
      return carfare_pay_sum;
    }
    get sum_sum() {
      let sum_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        sum_sum +=
          each_summery.reg_pay +
          each_summery.over_pay +
          each_summery.hol_pay +
          each_summery.mid_pay +
          each_summery.carfare_pay;
      });
      return sum_sum;
    }
    get reg_pay_sum_sum() {
      let reg_pay_sum_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        reg_pay_sum_sum += each_summery.reg_pay_sum;
      });
      return reg_pay_sum_sum;
    }
    get over_pay_sum_sum() {
      let over_pay_sum_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        over_pay_sum_sum += each_summery.over_pay_sum;
      });
      return over_pay_sum_sum;
    }
    get hol_pay_sum_sum() {
      let hol_pay_sum_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        hol_pay_sum_sum += each_summery.hol_pay_sum;
      });
      return hol_pay_sum_sum;
    }
    get mid_pay_sum_sum() {
      let mid_pay_sum_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        mid_pay_sum_sum += each_summery.mid_pay_sum;
      });
      return mid_pay_sum_sum;
    }
    get carfare_pay_sum_sum() {
      let carfare_pay_sum_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        carfare_pay_sum_sum += each_summery.carfare_pay_sum;
      });
      return carfare_pay_sum_sum;
    }
    get sum_sum_sum() {
      let sum_sum_sum = 0;
      this.summery_daily.forEach((each_summery) => {
        sum_sum_sum +=
          each_summery.reg_pay_sum +
          each_summery.over_pay_sum +
          each_summery.hol_pay_sum +
          each_summery.mid_pay_sum +
          each_summery.carfare_pay_sum;
      });
      return sum_sum_sum;
    }
  }
