
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinShift from '@/mixins/mixinShift';
import global from "@/vuex/shift/global";
import dialog from "@/vuex/dialog";
import CommonPager from "@/components/common/Pager.vue";

@Component({
  components: {
    CommonPager,
  }
})
export default class Monthchanger extends Mixins(mixinShift) {
  get yearmonthstr() {
    return this.filter.yearmonth(util.formatDate(this.dateobj, 'yyyy-mm-dd'));
  }

  prev_month() {
    var dateobj = new Date(this.dateobj);
    var tmp_day = dateobj.getDate();
    dateobj.setDate(1);
    dateobj.setMonth(dateobj.getMonth() - 1);
    var last_day = util.last_day(util.formatDate(dateobj, 'yyyy-mm-01'));
    if (tmp_day > last_day) {
      dateobj.setDate(last_day);
    } else {
      dateobj.setDate(tmp_day);
    }
    this.date = util.formatDate(dateobj, 'yyyy-mm-dd');
  }
  next_month() {
    var dateobj = new Date(this.dateobj);
    var tmp_day = dateobj.getDate();
    dateobj.setDate(1);
    dateobj.setMonth(dateobj.getMonth() + 1);
    var last_day = util.last_day(util.formatDate(dateobj, 'yyyy-mm-01'));
    if (tmp_day > last_day) {
      dateobj.setDate(last_day);
    } else {
      dateobj.setDate(tmp_day);
    }
    this.date = util.formatDate(dateobj, 'yyyy-mm-dd');
  }
}
