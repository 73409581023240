
  import { Component, Mixins } from "vue-property-decorator";
  import mixinShift from "@/mixins/mixinShift";
  import CommonProgress from "@/components/common/Progress.vue";
  import ShiftShiftHeaderMain from "@/components/shift/shift/header/Main.vue";
  import ShiftShiftMain from "@/components/shift/shift/Main.vue";
  import ShiftShiftEmployee from "@/components/shift/shift/Employee.vue";
  import ShiftShiftEmployeenew from "@/components/shift/shift/Employeenew.vue";

  @Component({
    components: {
      CommonProgress,
      ShiftShiftHeaderMain,
      ShiftShiftMain,
      ShiftShiftEmployee,
      ShiftShiftEmployeenew,
    },
  })
  export default class Main extends Mixins(mixinShift) {}
